
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import viewcontacts from "@/core/data/viewcontact";
import { IViewcontact } from "@/core/data/viewcontact";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    ErrorMessage,
    Field,
    Form,
    VPagination,
    // AddCustomerModal,
  },
  setup() {
    const Urlid = ref<any>("");
    const store = useStore();
    const route = useRoute();
    const submitButtonModel = ref<HTMLButtonElement | null>(null);
    const checkedViewContact = ref([]);
    const showTable = ref(false);
    const limit = ref(10);
    const total_pages = ref(1);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: ``,
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Replay status",
        key: "replay_status",
        sortable: true,
      },
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Mobile Number",
        key: "mobileno",
        sortable: true,
      },
      {
        name: "Property Address",
        key: "property_address",
        sortable: true,
      },
      {
        name: "Zip Code",
        key: "zip",
        sortable: true,
      },
      {
        name: "State",
        key: "state",
        sortable: true,
      },
      {
        name: "City",
        key: "city",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<any>>([]);
    const initCustomers = ref<Array<IViewcontact>>([]);
    const groupData = ref("");
    const chkcontactid = ref<Array<IViewcontact>>([]);
    const registration = Yup.object().shape({
      group_id: Yup.string().required().label("select Group"),
    });
    onMounted(() => {
      //  const Urlid = route.params.id;
      Urlid.value = route.params.id;
      setCurrentPageBreadcrumbs("View Contacts", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      // console.log('tableData',tableData.value);
      getGroupData();
      getItem(1);
    });

    const getItem = (page) => {
      var id = route.params.id;
      var request = {
        url: `/groupwise-conatct/${id}?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          console.log(total_pages.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };
    const getGroupData = () => {
      var request = {
        url: `group/all`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          groupData.value = data.data;
        }
      });
    };

    const deleteFewPlan = () => {
      checkedViewContact.value.forEach((item) => {
        deletePlan(item);
      });
      checkedViewContact.value.length = 0;
    };

    const deletePlan = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          var request = {
            url: `contact/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            if (data) {
              showTable.value = false;
              Swal.fire("Deleted!", "Contact has been deleted.", "success");
              getItem(1);
            }
          });
          // Swal.fire("Deleted!", "Plan has been deleted.", "success");
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IViewcontact> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const onSubmitRegister = (values, { resetForm }) => {
      submitButtonModel.value!.disabled = true;
      submitButtonModel.value?.setAttribute("data-kt-indicator", "on");

      if (chkcontactid.value.length == 0) {
        Swal.fire("Alert!", "Please select at-least one contact.", "alert");
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to change group for selected contacts.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update",
        }).then((result) => {
          if (result.isConfirmed) {
            var request = {
              url: `multipleNumber-groupChange`,
              data: {
                numbers: chkcontactid.value,
                group_id: values.group_id,
              },
            };
            store.dispatch(Actions.POST, request).then((data) => {
              if (data) {
                showTable.value = false;
                Swal.fire("Success!", "Group updated successfully.", "success");
                getItem(1);
              }
            });
          }
        });
      }

      submitButtonModel.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButtonModel.value!.disabled = false;
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };
    const checkAll = ref<any>(false);
    const handleCheckbox = (event) => {
      if (checkAll.value) {
        var arrSelected: any = [];
        for (let message of tableData.value) {
          arrSelected.push(message.mobileno);
        }
        chkcontactid.value = arrSelected;
      } else {
        chkcontactid.value = [];
      }
    };
    const page = ref(1);
    return {
      onSubmitRegister,
      tableData,
      tableHeader,
      deletePlan,
      search,
      searchItems,
      checkedViewContact,
      deleteFewPlan,
      getItem,
      dateTime,
      showTable,
      Urlid,
      groupData,
      registration,
      submitButtonModel,
      chkcontactid,
      total_pages,
      updateHandler,
      checkAll,
      handleCheckbox,
      page
    };
  },
});
